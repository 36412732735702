<template>
  <div class="idle-banner">
    <div class="desktop-banner">
      <template v-if="idle_banner">
        <img :src="idle_banner" alt="idle-banner" />
      </template>
      <template v-else>
        <img src="/static/img/dandelion-store/sociollaX-desktop-banner.png" alt="idle-banner-default" />
      </template>
    </div>
    <div class="mobile-banner">
      <img src="/static/img/dandelion-store/sociollaX-mobile-banner.png" alt="sociolla X" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DandelionStoreIndex',
  components: {},
  computed: {
    idle_banner() {
      return this.$store.state.dandelionStore.banners?.idle;
    },
  },
  created() {
    this.$store.dispatch('getDandelionStoreBanner', 'store_admin_price_checker_idle');
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .desktop-banner {
    display: none;
  }
  .mobile-banner {
    padding: 20%;
    img {
      border: 0px !important;
    }
  }
}
@media (min-width: 769px) {
  .mobile-banner {
    display: none;
  }
  .desktop-banner {
    height: 100vh;
    width: 100%;
  }
}

.idle-banner {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fcdfe2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
}
</style>
